body {
    background: url("./sisters.jpg") no-repeat center center fixed;
    background-size: cover;
}

.container {
    padding-top: 35%;
}

.forum {
    font-family: 'Forum', cursive;
    font-weight: normal;
    color: #000;
    padding: 4px 0px 0px 4px;
}

.title {
    font-size: 73px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.2em;
    padding-bottom: 100px;
    font-weight: 700;
}

.btn {
    border-radius: 0%;
    float: right;
    margin-right: 17px;
    font-family: 'Forum', cursive;
    font-weight: normal;
    background-color: #f1f1f1;

}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin-bottom: 20px;
}
.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 599px) {
    .row {
        margin: auto;
    }

    .header {
        width: 379px;
        margin-bottom: 10px;

    }

    .bandcamp {
        border: none;
        width: 375px;
        text-align: center;
        margin-left: -15px;
        margin-bottom: -10px;
    }

    .body {
        text-align: center;
    }

    .bg-grey {
        background-color: #f1f1f1;
        height: 45px;

    }
    .title {
        font-size: 50px;
    }
    
    .icon {
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 10px;
    }

    .youtube {
      border: 0; width: 375px;
     }

     .contact-form .input {
        width: 360px;
        margin-bottom: 5px;
    }

    .contact-form textarea {
        width: 360px;
        height: 110px;

    }

    .btn {
        margin-right: 5px;
    }

    .contact-form {
        margin-left: -11px;
    }

    
}

@media screen and (min-width: 600px) {
    .row {
        margin-bottom: 20px;
    }

    .contact-form {
        margin-left: 4px;
        width: 103%;
    }


    .contact-form .input {
        width: 539px;
        margin-bottom: 5px;

    }

    .contact-form textarea {
        width: 539px;
        height: 210px;
        
    }

    .icon {
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 10px;
    }

    .icon a{
        margin: 0px 0px 10px 4px;
        position: right;
    }
    .bg-grey {
        background-color: #f1f1f1;
        height: 45px;
        width: 539px;
        margin-bottom: 20px;
        margin-right: 60px;
    }

    .bandcamp {
        border: 0;
        width: 539px;
        height: 120px;
    }

    .top {
        margin-bottom: 15px;
     }

     .youtube {
      border: 0; width: 100%; height: 74%;
     }



}




